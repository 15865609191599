$('a:not([href^="tel:"]):not([target])').on('click tap', function(e) {

  var ref = location.href;
  var url = $(this).attr('href');

  if( ref.indexOf(url.replace(/#.*$/, '')) != -1 && url.indexOf('#') != -1 ) {

    var speed = 300;
    var href= url.match(/#.*$/);
    var target = $(href[0]);
    var position = target.offset().top - $('.js-header').outerHeight();
    $('body, html').animate({ scrollTop: position }, speed, 'swing');
    return false;

  }

});


$('.js-accrodion-btn').on('click tap', function(e) {

  if($(this).next('.js-accrodion-target').length !== 0) {
    $(this).next('.js-accrodion-target').slideToggle(
      function() {
        $(window).trigger('resize');
      }
    );
  } else {

    $(this).children('.js-accrodion-target').slideToggle(
      function() {
        $(window).trigger('resize');
      }
    );

  }

  $(this).toggleClass('is-active');
  $(window).trigger('resize');
});


if($('.js-view-box-toggle')[0] && $('.js-view-box')[0]) {

  $('.js-view-box').each(function() {
    if($(this).outerHeight() < 72) {
      $(this).toggleClass('is-active');
      $(this).next('.js-view-box-toggle').hide();
    }
  });

  $('.js-view-box-toggle').on('click tap', function(e) {
    $(this).prev('.js-view-box').toggleClass('is-active');
    $(this).remove();
  });

}

$('.js-campaign-close').on('click tap', function(e){

  $(this).parent('.js-campaign').css({'display':'none'});
  e.preventDefault();
})


$(window).on('load scroll', function() {

  if( $('.js-pagetop')[0] ) {
    var h = $(window).height();
    var scrollTop = $(window).scrollTop();
    if (scrollTop > h / 2) {
      $('.js-pagetop').addClass('is-active');
    }
    else {
      $('.js-pagetop').removeClass('is-active');
    }
  }

});


$(window).on('load resize', function() {

  var headerH = $('.js-header').outerHeight() + 20;

  var header_underH = $('.js-header__nav-categories').outerHeight();

  var window_width = window.innerWidth;

  // detail header
  if( $('.js-detail-header')[0] ) {
    $('.js-detail-header').css({
      'top': headerH,
    });
  }

  // .woocommerce .product .summary
  if( $('.woocommerce .product .summary')[0] ) {
    if(window_width > 1366) {
      $('.woocommerce .product .summary').css({
        'top': headerH,
      });
    } else if(window_width > 1199) {
      $('.woocommerce .product .summary').css({
        'top': headerH + header_underH,
      });

    }
  }

  // .cart_totals
  if( $('.cart_totals')[0] ) {
    $('.cart_totals').css({
      'top': headerH,
    });
  }

  // .woocommerce-checkout-review-order
  if( $('.woocommerce-checkout-review-order')[0] ) {
    $('.woocommerce-checkout-review-order').css({
      'top': headerH,
    });
  }

});

// Lazy Load
$(window).on('load resize scroll', function() {

  // Background
  $('[data-lazy-background]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).css('background-image', 'url('+$(this).data('lazy-background')+')');
      $(this).removeAttr('data-lazy-background');
    }
  });

  // img
  $('[data-lazy-img]').each(function() {
    if ($(window).scrollTop() + $(window).height() > $(this).offset().top) {
      $(this).attr('src', $(this).data('lazy-img'));
      $(this).removeAttr('data-lazy-img');
    }
  });

});


$(window).on('load', function() {

  if( $('.js-detail-header')[0] ) {
    // Sticky Polyfill
    var elem = document.querySelector('.js-detail-header');
    Stickyfill.add(elem);
  }

  if( $('.js-truncate')[0] ) {
    var truncate = Truncator.truncate;
    var truncateElm = document.getElementsByClassName('js-truncate');
    for (var i = 0; i < truncateElm.length; i++) {
      var line = truncateElm[i].dataset.line ? truncateElm[i].dataset.line : 2;
      truncate(truncateElm[i], truncateElm[i].textContent, { line: parseInt(line), ellipsis: '...' });
    }
  }

  var window_width = window.innerWidth;

  if(window_width < 1199) {
    $('.js-hamburger__categories').on('click tap', function(e){

        var cat_status = $(this).attr('data-category');

        if(cat_status == 'active') {

          $(this).attr('data-category','');
          $('.js-hamburger__categories-list').slideToggle();
          
        }

        else {
          $(this).attr('data-category','active');
          $('.js-hamburger__categories-list').slideToggle();
        }

      e.preventDefault();
    })
  }

  $('.js-menu-toggle').on('click tap', function() {
    var status = $('body').attr('data-menu');
    var cat_status = $('.js-hamburger__categories-list').attr('data-category');

    if( status == 'active' ) {

      $('body').attr('data-menu', '');
      $('.js-hamburger').attr('data-under-menu', '');

      if(cat_status == 'active') {
        $('.js-hamburger__categories-list').attr('data-category','');
      }

    } else {

      $('body').attr('data-menu', 'active');

      setTimeout(function() {
        $('.js-hamburger').attr('data-under-menu', 'active');
      }, 300);

    }
  });

  $('.js-select-toggle').on('click tap', function() {
    var status = $('body').attr('data-select');
    if( status == 'active' ) {
      $('body').attr('data-select', '');
    } else {
      $('body').attr('data-select', 'active');
    }
  });

  $('.js-search-toggle').on('click tap', function() {
    var status = $('body').attr('data-search');
    if( status == 'active' ) {
      $('body').attr('data-search', '');
    } else {
      $('body').attr('data-search', 'active');
    }
  });

  $('.js-cart-toggle').on('click tap', function() {
    var status = $('body').attr('data-cart');
    if( status == 'active' ) {
      $('body').attr('data-cart', '');
    } else {
      $('body').attr('data-cart', 'active');
    }
  });

  $('.js-pagetop-btn').on('click tap', function() {
    $('body, html').animate({
      scrollTop: 0
    }, 300, 'swing');
  });

  if( location.hash && $(location.hash)[0] ) {

    var hash = location.hash;

    setTimeout( function() {
      $('body, html').animate({ scrollTop: $(hash).offset().top - $('.js-header').outerHeight() }, 0, 'linear');
    }, 300 );

    $('.p-gnav-link').each(function() {
      if(location.href.indexOf($(this).attr('href')) > 0) {
      $('.p-gnav-link').removeClass('is-active');
        $(this).addClass('is-active');
      }
    });

    if( $(location.hash).hasClass('js-accrodion-btn') ) {
      $(location.hash).trigger('click');
    }

  }

  if( $('.js-visual-main')[0] ) {

    var visualSub = new Swiper('.js-visual-sub', {
      loop: true,
      speed: 500,
      slidesPerView: 1,
      loopAdditionalSlides: 1,

    });

    var visualMain = new Swiper('.js-visual-main', {
      loop: true,
      speed: 500,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      paginationClickable: true,
      pagination: {
        el: '.js-visual-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
          
          var bullet_num = ('00' + (index + 1)).slice(-2);

          return '<span class="' + className + '">' + bullet_num + '</span>';
        },
      },
      
      navigation: {
        nextEl: '.js-visual-next',
        prevEl: '.js-visual-prev',
      },
    });

    visualMain.controller.control = visualSub;
    visualSub.controller.control = visualMain;

  }

  if( $('.js-style-swiper')[0] ) {

    var styleSub = new Swiper('.js-style-swiper-sub', {
      loop: true,
      speed: 500,
      spaceBetween: 20,
      slidesPerView: 4,
      loopAdditionalSlides: 1,
      direction: 'vertical',
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,

      breakpoints: {
        1023: {
          direction: 'horizontal',
          slidesPerView: 'auto'
        },

        767: {
          direction: 'horizontal',
          slidesPerView: 'auto',
          spaceBetween: 10,
        }
      },
    });

    var styleMain = new Swiper('.js-style-swiper', {
      loop: true,
      speed: 500,
      slidesPerView: 1,
      loopAdditionalSlides: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },      
      navigation: {
        nextEl: '.js-style-next',
        prevEl: '.js-style-prev',
      },

      thumbs: {
        swiper: styleSub
      },
    });

  }

  loadFile('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap', 'css');
  loadFile('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap', 'css');
  loadFile('https://use.fontawesome.com/releases/v5.6.1/css/all.css', 'css');

  $('body').attr('data-status', 'loaded');

  //ずれるので移動
  if( $('.js-pickup')[0] ) {
    if(window_width > 1024) {
      //match height
      $('.js-pickup-slide').matchHeight({
        byRow: false
      });
    }

    var pickup = new Swiper('.js-pickup', {
      loop: true,
      speed: 500,
      slidesPerView: 1,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      paginationClickable: true,
      pagination: {
        el: '.js-pickup-pagination',
        type: 'bullets',
        clickable: true,
        renderBullet: function (index, className) {
          
          var bullet_num = ('00' + (index + 1)).slice(-2);
  
          return '<span class="' + className + '">' + bullet_num + '</span>';
        },
      },
      
      navigation: {
        nextEl: '.js-pickup-next',
        prevEl: '.js-pickup-prev',
      },
    });

  }

  
  if( $('.woocommerce-product-gallery__image')[0] ) {

    $('.woocommerce-product-gallery__image').each(function() {
      var src = $(this).find('img').attr('data-src');
      $('.js-product-images .swiper-wrapper').append('<li class="swiper-slide"><div style="background-image: url('+src+');"></div></li>');
    });

    if( $('.woocommerce-product-gallery__image')[1] ) {
      var productImages = new Swiper('.js-product-images', {
        loop: true,
        speed: 1000,
        slidesPerView: 'auto',
        loopAdditionalSlides: 1,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: '.js-product-images-next',
          prevEl: '.js-product-images-prev',
        },
        on: {
          slideChange: function () {
            slideThumbnailActive(this, '.woocommerce-product-gallery__image');
          },
        },
      });

      $('.woocommerce-product-gallery__image').on('click tap', function() {
        var index = $('.woocommerce-product-gallery__image').index(this);
        productImages.slideTo(index);
      });

      slideThumbnailActive(productImages, '.woocommerce-product-gallery__image');
    } else {
      $('.js-product-images-next, .js-product-images-prev').hide();
    }

  }


});

function slideThumbnailActive(slider, thumbnail) {
  var index = slider.realIndex;
  $(thumbnail).removeClass('is-active');
  $(thumbnail).eq(index).addClass('is-active');
}


function loadFile(src, type) {

  if( type == 'js' ) {
    $('body').append('<script src="'+src+'"></script>');
  }

  else if( type == 'css' ) {
    $('body').append('<link rel="stylesheet" href="'+src+'">');
  }

  else {
    return false;
  }

}

$(window).on('load',function() {

  //match height
  $('.js-category-item').matchHeight({
    byRow: false
  });

  var window_width = window.innerWidth;

  var count = $('.js-category-item').length;

  var item_height = 0;

  var natural_height = $('.js-category-container').css('height', 'auto').height();

  if(count > 5) {

    for(i = 0; i < 5; i++) {

      var item_height_comparsion = $('.js-category-item').eq(i).outerHeight();

      if(item_height < item_height_comparsion) {

        item_height = item_height_comparsion;

      }
    }

    if(window_width > 1024) {

      $('.js-category-container').css({
        'height': Math.floor(item_height),
      });

    }
    
    else {

      $('.js-category-container').css({
        'height': Math.floor(item_height * 2),
      });
    }


  }

  $('.js-category-more').on('click tap', function(e){

    var flg = $('.js-category-container').attr('data-status');

      if(flg == '') {

        $('.js-category-container').attr('data-status','is-open').animate({height: natural_height} ,500,'swing');

        $('.js-category-more').text('CLOSE');

      } else {

        if(window_width > 1024) {
          $('.js-category-container').attr('data-status','').animate({height: Math.floor(item_height)} ,500,'swing');
        
        }

        else {
          $('.js-category-container').attr('data-status','').animate({height: Math.floor(item_height * 2)} ,500,'swing');

        }

        $('.js-category-more').text('VIEW MORE');

      }

    e.preventDefault();

  });

})

$(window).on('load', function() {

  var window_width = window.innerWidth;

  if($('.js-ranking').length !== 0) {

    var negative_margin = 15;

    if(window_width < 768) {
      negative_margin = 7.5;
    }

    $('.js-ranking-container').mousewheel(function(event, mov){
      $(this).scrollLeft($(this).scrollLeft() - mov * 80);
      return false;   //縦スクロール不可
    });
    
    var scroller_class = document.getElementsByClassName('js-ranking');
    
    var scrollable_width = $('.js-ranking-container').width() + negative_margin, //外側の幅 + はネガティブマージン分
    
        adjustment_width = scroller_class[0].scrollWidth, //スクロール内の幅
    
        scroller_width = $('.js-ranking-scroll-bar').width(), //スクロールバー全体の幅
    
        scrollbar_width = parseInt(scrollable_width * scrollable_width / adjustment_width);
        
        scrollbar__width = scrollbar_width / adjustment_width; //割合を出す
    
        scrollbar__width = scroller_width * scrollbar__width; //反映

    
    $('.js-ranking-scroll-thumb').css('width', scrollbar__width);
    
    var scrollbar_track = scroller_width - scrollbar__width; //スクロールサムを除いた幅

    $('.js-ranking-container').on('scroll', function() {
      var offset = $(this).scrollLeft() / (adjustment_width - scrollable_width) * scrollbar_track;

      $('.js-ranking-scroll-thumb').css('transform', 'translateX(' + offset + 'px)');
    });
    
    var active = false, // つまみを操作しているかどうか
        scrollbar_thumb_cursor_x; // つまみ内のクリック位置
    
    $('.js-ranking-scroll-thumb').on('mousedown', function(event) {
      active = true;
      scrollbar_thumb_cursor_x = event.pageX - $(this).offset().left;
    });
    
    $(document).on('mouseup', function() {
      active = false;
    });
    
    $(document).on('mousemove', function(event) {
      if (!active) return;
      
      var scrollbar_thumb_x = ((event.pageX - $('.js-ranking-scroll-bar').offset().left) / scrollbar_track * scrollbar_track) - scrollbar_thumb_cursor_x;

      // つまみが外にでないようにする
      if (scrollbar_thumb_x < 0) {
        scrollbar_thumb_x = 0;

      } else if (scrollbar_thumb_x > scrollbar_track) {
        scrollbar_thumb_x = scrollbar_track;

      }
      
      // つまみの位置設定
      $('.js-ranking-scroll-thumb').css('transform', 'translateX(' + scrollbar_thumb_x + 'px)');
    
      // つまみの位置に応じてスクロールさせる
      $('.js-ranking-container').scrollLeft(($('.js-ranking-scroll-thumb').offset().left - $('.js-ranking-scroll-bar').offset().left) / scrollbar_track * (adjustment_width - scrollable_width));
    });
    
    // つまみを操作中はテキスト選択できないようにする
    $(document).on('selectstart', function() {
      if (active) return false;
    });
    
  }
  
});

//スクロールの上下で出し入れ

var scroll_neutral = 0;

$(window).on('scroll',function(){
  var scroll_position = $(window).scrollTop();

  if(scroll_neutral < scroll_position) {
    $('.js-side-menu').removeClass('is-reverse');
  } 
  
  else {
    $('.js-side-menu').addClass('is-reverse');
  }

  scroll_neutral = scroll_position;
})

// Swiper 
Swiper.prototype.getBreakpoint = function (breakpoints) {

  var window_size = window.innerWidth
  || document.documentElement.clientWidth
  || document.body.clientWidth;

  var swiper = this;
  // Get breakpoint for window width
  if (!breakpoints) {
      return undefined;
  }
  var breakpoint = false;
  var points = [];
  Object.keys(breakpoints).forEach(function (point) {
      points.push(point);
  });
  points.sort(function (a, b) {
      return parseInt(a, 10) - parseInt(b, 10);
  });
  for (var i = 0; i < points.length; i += 1) {
      var point = points[i];
      if (swiper.params.breakpointsInverse) {
          if (point <= window_size) {
              breakpoint = point;
          }
      } else if (point >= window_size && !breakpoint) {
          breakpoint = point;
      }
  }
  return breakpoint || 'max';
};

